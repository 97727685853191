
// Save GDPR COOKIE PREFERENCES on registry.devinterface.com
submitGDPRLog = function (event, pageUrl, apiUrl, apiKey, bannerName, policyUrl) {
  var xhr = new XMLHttpRequest();

  xhr.addEventListener("readystatechange", function() {
    if(this.readyState != 200) {
      // Print errors
      console.log(this.responseText);
    }
  });

  // Get user IP & POST to registry
  $.getJSON('https://api.ipify.org?format=jsonp&callback=?', function(data) {

    let uri = apiUrl;
    let jsonData = JSON.stringify(data, null, 2);
    uri += "ip_address=" + JSON.parse(jsonData).ip;
    uri += "&consent_page_url=" + pageUrl;
    uri += "&transaction_code=" + Date.now();
    uri += "&user_agent=" + navigator.userAgent.replaceAll(';', ' ');
    uri += "&country_code=" + navigator.language;
    uri += "&cookie_banner=" + bannerName;
    let jsonEvent = JSON.stringify(event, null, 2);
    let cookiePreference = JSON.stringify(JSON.parse(jsonEvent).purposes);
    uri += "&cookie_preference=" + cookiePreference;
    uri += "&policy_url=" + policyUrl;

    xhr.open("POST", encodeURI(uri));
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("x-api-key", apiKey);

    resp = xhr.send();
  });
}